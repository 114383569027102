/* eslint-disable react/react-in-jsx-scope */
import * as React from 'react'
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { AxiosResponse } from 'axios'
import {
  Comment,
  CompletionStatus,
  TemplateReportDailyData,
} from '@src/generatedClient/generated/taskreportsApi'

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  dateHeader: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 10,
    textDecoration: 'underline',
  },
  completionItem: {
    marginBottom: 15,
    borderBottom: '1pt solid #ccc',
    paddingBottom: 10,
  },
  completionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  completionMeta: {
    marginTop: 5,
    marginBottom: 5,
  },
  status: {
    padding: '4pt 8pt',
    borderRadius: 4,
    fontSize: 10,
    display: 'flex',
  },
  commentSection: {
    marginLeft: 20,
    marginTop: 5,
  },
  comment: {
    marginBottom: 10,
  },
  imageRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
  },
  imageContainer: {
    marginRight: 10,
    marginBottom: 10,
  },
  imageLandscape: {
    width: 476,
    height: 357,
  },
  imagePortrait: {
    width: 476,
    height: 357,
  },
  noActivityMessage: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 40,
    fontStyle: 'italic',
  },
})

const findComments = (
  commentIds: string[] | undefined,
  comments: Comment[],
): Comment[] => {
  if (!commentIds) return []
  return comments.filter((comment) => commentIds.includes(comment.id))
}

const formatDateTime = (date: string): string =>
  dayjs(date).format('MMM D, YYYY h:mm A')

// Comment component
function CommentBlock({ comment }: { comment: Comment }) {
  return (
    <View style={styles.comment}>
      <Text>{comment.userNote}</Text>
      {comment.fileUrls && comment.fileUrls.length > 0 && (
        <View style={styles.imageRow}>
          {comment.fileUrls
            .filter((url) => url.toLowerCase().endsWith('.jpg'))
            .map((url, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <View key={index} style={styles.imageContainer}>
                <Image src={url} style={styles.imageLandscape} />
              </View>
            ))}
        </View>
      )}
    </View>
  )
}

// Main component
export function SinglePagePdf(props: {
  date: string
  data: AxiosResponse<TemplateReportDailyData>
  escLookup: Record<string, string>
}) {
  const { date, data, escLookup } = props
  const { comments, completions, escOccurrences } = data.data

  const completedActivities = completions.filter(
    (completion) => completion.status === CompletionStatus.complete,
  )

  const hasNoActivity =
    completedActivities.length === 0 && escOccurrences.length === 0

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.dateHeader}>{dayjs(date).format('MM/DD/YYYY')}</Text>

      {hasNoActivity ? (
        <Text style={styles.noActivityMessage}>
          No activities or escalations recorded for this day
        </Text>
      ) : (
        <>
          {completedActivities.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Activity</Text>
              {completedActivities.map((completion, i) => (
                <View
                  break={i !== 0}
                  key={completion.id}
                  style={styles.completionItem}
                >
                  <Text style={styles.completionTitle}>{completion.title}</Text>
                  <View style={styles.completionMeta}>
                    <Text>Description: {completion.description}</Text>
                    <Text>
                      Completed: {formatDateTime(completion.completedAtTime)}
                    </Text>
                  </View>
                  {completion.commentIds &&
                    completion.commentIds.length > 0 && (
                      <View style={styles.commentSection}>
                        <Text style={{ marginBottom: 5, fontWeight: 'bold' }}>
                          Details:
                        </Text>
                        {findComments(completion.commentIds, comments).map(
                          (comment) => (
                            <CommentBlock key={comment.id} comment={comment} />
                          ),
                        )}
                      </View>
                    )}
                </View>
              ))}
            </View>
          )}

          {escOccurrences.length > 0 && (
            <View break style={styles.section}>
              <Text style={styles.sectionTitle}>Escalations</Text>
              {escOccurrences.map((escalation, i) => (
                <View
                  break={i !== 0}
                  key={escalation.id}
                  style={styles.completionItem}
                >
                  <Text style={styles.completionTitle}>
                    Escalation:{' '}
                    {escLookup[escalation.escalationId] || 'Problem'}
                  </Text>
                  <View style={styles.completionMeta}>
                    <Text>Created: {formatDateTime(escalation.cts)}</Text>
                    {escalation.resolvedDate && (
                      <Text>
                        Resolved: {formatDateTime(escalation.resolvedDate)}
                      </Text>
                    )}
                  </View>
                  {escalation.commentIds &&
                    escalation.commentIds.length > 0 && (
                      <View style={styles.commentSection}>
                        <Text style={{ marginBottom: 5, fontWeight: 'bold' }}>
                          Details:
                        </Text>
                        {findComments(escalation.commentIds, comments).map(
                          (comment) => (
                            <CommentBlock key={comment.id} comment={comment} />
                          ),
                        )}
                      </View>
                    )}
                </View>
              ))}
            </View>
          )}
        </>
      )}
    </Page>
  )
}
