/* eslint-disable no-await-in-loop */
import React, { useRef, useState } from 'react'
import { Document, pdf } from '@react-pdf/renderer'
import { PDFDocument } from 'pdf-lib'
import { SinglePagePdf } from '@src/modules/Sites/router/screens/ViewSite/components/SinglePagePdf'
import { AxiosResponse } from 'axios'

import {
  EscOccurrence,
  getEscalation,
  TemplateReportDailyData,
} from '@src/generatedClient/generated/taskreportsApi'

type Status = 'idle' | 'generating' | 'combining' | 'complete' | 'error'
type EscalationLookup = Record<string, string>

type DataArg = {
  date: string
  data: AxiosResponse<TemplateReportDailyData>
}

interface SequentialPDFGeneratorProps {
  fetchFunction: (arg: string) => Promise<DataArg>
  arguments: string[]
}

export function SequentialPDFGenerator({
  fetchFunction,
  arguments: args,
}: SequentialPDFGeneratorProps): JSX.Element {
  const [status, setStatus] = useState<Status>('idle')
  const [progress, setProgress] = useState<number>(0)
  const [escLookup, setEscLookup] = useState<EscalationLookup>({})
  const currentLookupRef = useRef<EscalationLookup>({})

  const fetchMissingEscalations = async (
    escOccurrences: EscOccurrence[],
  ): Promise<EscalationLookup> => {
    const uniqueEscalationIds = Array.from(
      new Set(escOccurrences.map((o) => o.escalationId)),
    ).filter((id) => !currentLookupRef.current[id])

    if (uniqueEscalationIds.length === 0) {
      return currentLookupRef.current
    }

    const newEscalations = await Promise.all(
      uniqueEscalationIds.map(async (id) => {
        const response = await getEscalation({ id })
        return [id, response.data?.name || 'Unknown'] as const
      }),
    )

    const updatedLookup = {
      ...currentLookupRef.current,
      ...Object.fromEntries(newEscalations),
    }

    // Update both the state and our ref
    setEscLookup(updatedLookup)
    currentLookupRef.current = updatedLookup

    return updatedLookup
  }

  const generatePDFForData = async (
    date: string,
    data: AxiosResponse<TemplateReportDailyData>,
  ): Promise<ArrayBuffer> => {
    // Get the updated lookup and wait for it
    const updatedLookup = await fetchMissingEscalations(
      data.data.escOccurrences,
    )

    function PageDocument() {
      return (
        <Document>
          <SinglePagePdf
            date={date}
            data={data}
            escLookup={updatedLookup} // Use the directly returned lookup
          />
        </Document>
      )
    }

    try {
      const blob = await pdf(<PageDocument />).toBlob()
      console.log('Generated PDF blob size:', blob.size)
      return blob.arrayBuffer()
    } catch (error) {
      console.error('Error generating individual PDF:', error)
      throw error
    }
  }

  const generateAndCombinePDFs = async (): Promise<void> => {
    try {
      setStatus('generating')
      const pdfDocs: ArrayBuffer[] = []

      for (let i = 0; i < args.length; i++) {
        console.log(`Processing document ${i + 1} of ${args.length}`)
        const pageData = await fetchFunction(args[i])
        const arrayBuffer = await generatePDFForData(
          pageData.date,
          pageData.data,
        )
        console.log(`Generated PDF ${i + 1} size:`, arrayBuffer.byteLength)
        pdfDocs.push(arrayBuffer)
        setProgress(Math.round(((i + 1) / args.length) * 100))
      }

      setStatus('combining')
      console.log('Starting PDF combination')

      const mergedPdf = await PDFDocument.create()

      for (const pdfBytes of pdfDocs) {
        const p = await PDFDocument.load(pdfBytes)
        console.log('Loaded PDF page count:', p.getPageCount())
        const copiedPages = await mergedPdf.copyPages(p, p.getPageIndices())
        copiedPages.forEach((page) => mergedPdf.addPage(page))
      }

      const mergedPdfFile = await mergedPdf.save()
      console.log('Final merged PDF size:', mergedPdfFile.byteLength)

      const blob = new Blob([mergedPdfFile], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = 'combined.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)

      setStatus('complete')
    } catch (error) {
      console.error('Error in PDF generation process:', error)
      setStatus('error')
    }
  }

  return (
    <div className="p-4">
      <button
        onClick={generateAndCombinePDFs}
        type="button"
        disabled={status === 'generating' || status === 'combining'}
        className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
      >
        {status === 'idle' && 'Generate PDF'}
        {status === 'generating' && `Generating PDFs (${progress}%)`}
        {status === 'combining' && 'Combining PDFs...'}
        {status === 'complete' && 'Generation Complete'}
        {status === 'error' && 'Error Generating PDFs'}
      </button>

      {status !== 'idle' && (
        <div className="mt-4">
          <div className="h-2 bg-gray-200 rounded">
            <div
              className="h-full bg-blue-500 rounded transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
